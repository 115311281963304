<template>
  <footer>
    <div class="wrapper">
      <!--div class="bottom-img"></div-->
      <div class="nav-bottom">
        <div class="footerInfo">
          <div class="footerl">
            <a class="flogo" @click="goPageByName('main')">
              <img src="@/assets/img/bluelogo.png" />
              <span class="copyright">Copyright 2023 Blue. All Rights Reserved.</span>
            </a>
          </div>
          <div class="footerr">
            <ul class="footerNav">
              <li @click="goPageByName('qnaWrite')">{{ $t('front.common.qna')}}</li>
              <li @click="goPageByName('notice')">{{ $t('front.common.notice')}}</li>
              <li @click="goPageByName('faq')">{{ $t('front.common.faq')}}</li>
              <li @click="goPageByName('bettingGuide')">{{ $t('front.common.bettingGuide')}}</li>
              <li @click="goPageByName('result')">{{ $t('front.common.gameResult')}}</li>
              <li @click="goPageByName('boardList')">{{ $t('front.common.board')}}</li>
            </ul>
            <ul class="sns" v-if="siteOption">
              <li @click="siteOption['sns2Link']">
                <img src="@/assets/img/tele.svg" />
                <span>{{siteOption['sns2']}}<em>{{siteOption['sns2Id']}}</em></span>
              </li>
              <li @click="siteOption['sns1Link']">
                <img src="@/assets/img/kakao.svg" />
                <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
              </li>
              <li v-if="siteOption['sns3']" @click="siteOption['sns3Link']">
                <img src="@/assets/img/zalo.svg" />
                <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
